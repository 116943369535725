/* eslint-disable no-underscore-dangle */
import I18next from 'i18next';
import { isPlainObject, debounce, uniq, isString, isArray } from 'lodash';
import { renderToString } from 'react-dom/server';
import { getTerms } from '../services/admin/translate';
import { TRAD, PROD, VERIF } from '../constants/a18n';

export const DEFAULT_OPTIONS = {
  mode: PROD, // determine the i18n mode
  replace: [], // array of tuple ([str or str[], str or component])
  link: false, // will insert the text in a link when using the component
};

// force the PROD mode in prod
const OVERRIDE_OPTIONS = process.env.RAILS_ENV === 'production' ? { mode: PROD } : {};

export function getOptions(options) {
  const windowOpts = typeof window !== 'undefined' && isPlainObject(window.artprice?.a18nOptions) ? window.artprice.a18nOptions : {};
  return { ...DEFAULT_OPTIONS, ...windowOpts, ...options, ...OVERRIDE_OPTIONS };
}

function reducer(translation, replaceTuple) {
  const [strToReplace, replacement] = replaceTuple;

  if (isString(translation)) {
    const splitted = translation.split(strToReplace);

    const result = [];
    for (let i = 0; i < splitted.length - 1; i += 1) {
      result.push(splitted[i]);
      result.push(typeof replacement === 'object' ? renderToString(replacement) : replacement);
    }
    result.push(splitted[splitted.length - 1]);
    return result.join('');
  }
  if (isArray(translation)) {
    return translation.flatMap(token => reducer(token, replaceTuple));
  }
  return translation;
}

const rsxs = [];
const debouncedCacheUpdate = debounce(async () => {
  const terms = await getTerms(uniq(rsxs).join(','));
  Object.entries(terms).forEach(([rsx, term]) => {
    sessionStorage.setItem(`a18n_${rsx}`, String(term));
  });
  rsxs.splice(0, rsxs.length); // emptying array in place
}, 50);

async function addToCacheQueue(rsx) {
  rsxs.push(rsx);
  debouncedCacheUpdate();
}

function getFromCache(rsx, backgroundUpdate = false) {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    if (backgroundUpdate) {
      (window.requestIdleCallback || window.setTimeout)(() => {
        addToCacheQueue(rsx);
      });
    }

    const term = sessionStorage.getItem(`a18n_${rsx}`);
    if (term) {
      return term;
    }
  }
  return I18next.t(rsx);
}

export function a18n(rsx, _options = {}) {
  const options = getOptions(_options);
  let term;
  switch (options.mode) {
    case PROD:
      term = I18next.t(rsx);
      break;
    case TRAD:
      term = rsx;
      break;
    case VERIF:
      term = getFromCache(rsx, true);
      break;
    default:
      throw new Error(`Unknown mode: ${options.mode}`);
  }

  return options.replace.reduce(reducer, term);
}
