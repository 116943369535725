import React from 'react';
import '../../../common/stylesheets/buttons.scss';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Help = () => (
  <div className="payment-common-help">
    <h3>
      <A18n rsx="payments.common.cartsummary.m7" />
    </h3>

    <p>
      <button
        className="artp-btn artp-btn-purple"
        type="button"
        onClick={() => {
          window.open('/contact?active=block8&id=690', '_blank');
        }}
      >
        <A18n rsx="payments.common.cartsummary.m8" />
      </button>
    </p>
  </div>
);

export default Help;
