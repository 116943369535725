import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { getUserContext } from '../../../../redux/selectors/userContext';
import { getCart } from '../../../../redux/slices/payments';
import AvailablePayments from '../AvailablePayments';
import A18n from '../../../common/A18n';
import Promocode from './Promocode';
import Help from '../Help';
import './stylesheet.scss';

const CartSummary = ({ withPromocode }) => {
  const intl = useIntl();
  const cart = useSelector(getCart);
  const [product, setProduct] = useState();
  const [total, setTotal] = useState(0);
  const { lastSubscription } = useSelector(getUserContext);
  const [advantages, setAdvantages] = useState([]);

  useEffect(() => {
    if (cart?.main_products?.[0]?.product && !cart.loading) {
      setProduct(cart.main_products[0].product);
      setTotal(cart.main_products.reduce((acc, lineCart) => acc + lineCart.product.prices[cart.currency.iso3.toLowerCase()] * lineCart.product_quantity, 0));
      setAdvantages(cart.main_products[0].component_products.filter(lineCart => lineCart.prices[cart.currency.iso3.toLowerCase()] < 0));
    }
  }, [cart]);

  if (!product || cart.loading) return null;

  return (
    <div className="payments-common-cart-summary">
      {/* <h3 className="header">Récapitulatif</h3> */}
      <div className="encart">
        <div className="row">
          <h3>
            {product.theme && (
              <>
                <span>{product.theme}</span>
                {/* eslint-disable-next-line react/style-prop-object */}
                <FormattedNumber value={total} style="currency" currency={cart.currency.iso3.toLowerCase()} />
              </>
            )}

            {!product.theme && <span>{product.title}</span>}
          </h3>
        </div>
        <div className="row cart">
          <ul>
            {!product.theme && (
              <li className="non-themed-product">
                {/* eslint-disable-next-line react/style-prop-object */}
                <FormattedNumber value={total} style="currency" currency={cart.currency.iso3.toLowerCase()} />
              </li>
            )}
            {product.theme && (
              <li>
                &#x2714; {cart.main_products[0].product_quantity > 1 && <A18n rsx="payments.common.cartsummary.m1" replace={[['%nb%', cart.main_products[0].product_quantity]]} />}
                {cart.main_products[0].product_quantity === 1 && <A18n rsx="payments.common.cartsummary.m2" />}
              </li>
            )}
            {cart.main_products[0].product.duration === 0 && (
              <li>
                &#x2714; <A18n rsx="payments.common.cartsummary.m13" />
              </li>
            )}
            {cart.main_products[0].product.duration > 0 && (
              <li>
                &#x2714; {cart.main_products[0].product.duration === 1 && <A18n rsx="payments.common.cartsummary.m14" />}
                {cart.main_products[0].product.duration === 12 && <A18n rsx="payments.common.cartsummary.m15" />}
                {cart.main_products[0].product.duration === 24 && <A18n rsx="payments.common.cartsummary.m16" />}
                {cart.main_products[0].product.daysAdded > 1 && <A18n rsx="payments.common.cartsummary.m3" replace={[['%nbdays%', cart.main_products[0].product.daysAdded]]} />}
                {cart.main_products[0].product.daysAdded === 1 && <A18n rsx="payments.common.cartsummary.m4" />}
              </li>
            )}
            {cart.main_products[0].product.duration === 1 && (
              <>
                <li>
                  &#x2714; <A18n rsx="payments.common.cartsummary.m9" />
                </li>
                <li>
                  &#x2714; <A18n rsx="payments.common.cartsummary.m10" />
                </li>
              </>
            )}
            {product.dtstart && product.duration > 1 && (
              <li>
                &#x2714;{' '}
                {lastSubscription && (
                  <A18n
                    rsx="payments.common.cartsummary.m5"
                    replace={[
                      ['%dtstart%', intl.formatDate(product.dtstart)],
                      ['%dtend%', intl.formatDate(new Date(product.dtexpire))],
                    ]}
                  />
                )}
                {!lastSubscription > 0 && <A18n rsx="payments.common.cartsummary.m6" replace={[['%dt%', intl.formatDate(product.dtexpire)]]} />}
              </li>
            )}
          </ul>
        </div>
      </div>

      {!isEmpty(advantages) && (
        <div className="cart-details">
          <h4>
            {advantages.length > 1 && <A18n rsx="payments.common.cartsummary.m11" />}
            {advantages.length === 1 && <A18n rsx="payments.common.cartsummary.m12" />}
          </h4>

          {advantages.map(lineCart => (
            <div className="line" key={`${lineCart.id}-${lineCart.prices[cart.currency.iso3.toLowerCase()]}-${lineCart.quantity}`}>
              {lineCart.title}{' '}
              {lineCart.prices[cart.currency.iso3.toLowerCase()] !== 0 && (
                <span>
                  {/* eslint-disable-next-line react/style-prop-object */}
                  ( <FormattedNumber value={lineCart.prices[cart.currency.iso3.toLowerCase()] * lineCart.quantity} style="currency" currency={cart.currency.iso3} /> )
                </span>
              )}
            </div>
          ))}
        </div>
      )}

      {withPromocode && <Promocode />}

      <div className="help-on-desktop">
        <Help />
        <AvailablePayments />
      </div>
    </div>
  );
};

export default CartSummary;
