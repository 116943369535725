/* eslint-disable global-require */
module.exports = [
  ...require('./Links/i18n'),
  'payments.ok.m1',
  'payments.ok.m2',
  'payments.ok.m3',
  'payments.ok.m4',
  'payments.ok.m5',
  'payments.ok.m6',
  'payments.ok.m7',
  'payments.ok.m8',
  'payments.ok.m9',
  'payments.ok.m10',
];
