import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { navigationStep } from '../../../../redux/slices/payments';
import A18n from '../../../common/A18n';
import './stylesheet.scss';
import { IMAGE_SOURCE } from '../../../../constants/rails';
import { useViewport } from '../../../../hooks/useViewport';

const config = [
  {
    label: <A18n rsx="payments.layout.navigation.m2" />,
    step: 1,
  },
  {
    label: <A18n rsx="payments.layout.navigation.m3" />,
    step: 2,
  },
  {
    label: <A18n rsx="payments.layout.navigation.m4" />,
    step: 3,
  },
];

const Navigation = () => {
  const currentStep = useSelector(navigationStep);
  const { viewportWidth } = useViewport();

  return (
    <div className="payments-navigation">
      <div className="container">
        <div
          className="logo"
          onClick={() => {
            window.location = '/';
          }}
        >
          <img src={`${IMAGE_SOURCE}logo.svg`} alt="artprice" />
        </div>
        {config.map(({ label, step }) => (
          <div
            className={classNames({
              step: true,
              hidden: viewportWidth < 768 && step !== currentStep,
              checked: step < currentStep,
              active: step === currentStep,
            })}
            key={`idx${step}`}
          >
            <div className="icon">
              {currentStep > step && <span>&#x2714;</span>}
              {currentStep <= step && <span>{step}</span>}
            </div>
            <div className="label">{label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Navigation;
