import React from 'react';
import i18next from 'i18next';
import classNames from 'classnames';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { ANALYTICS_CLICK_OPERATION_TO_CGV, ANALYTICS_FROM_FOOTER } from '../../../../../constants/rails';
import { getIdcustomer } from '../../../../../redux/slices/userContext/selectors';
import { getCart, getParams } from '../../../../../redux/slices/payments';
import Legals, { CGV, PRIVACY, PRODUCT } from '../../../../Legals';
import ErrorBlock from '../common/ErrorBlock';
import A18n from '../../../../common/A18n';

const Cgv = () => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { main_products: mainProducts } = useSelector(getCart);
  const { image, promocode, type, quantity, idphysicproducts } = useSelector(getParams);
  const isRecurrentSubscription = get(mainProducts, '[0].product.isRecurrentSubscription');
  const idcustomer = useSelector(getIdcustomer);

  return (
    <div className="payments-informations-form-cgv" style={{ marginTop: 25 }}>
      {!idcustomer && (
        <div className="group">
          <div className="cell">
            <div className="checkboxes">
              <div>
                <input type="checkbox" id="communications" {...register('communications')} />
              </div>
              <div
                onClick={e => {
                  if (e.target.tagName.toLowerCase() !== 'a') {
                    setValue('communications', !getValues('communications'));
                  }
                }}
              >
                <A18n rsx="payments.informations.form.cgv.m8" />
              </div>
            </div>
          </div>
        </div>
      )}
      {isRecurrentSubscription && (
        <div className="group">
          <div className="cell">
            <div className="checkboxes">
              <div>
                <input type="checkbox" {...register('checkMonthly')} id="sln_valid_monthly" className={classNames({ warning: !isEmpty(errors.checkMonthly) })} />
              </div>
              <div style={{ cursor: 'pointer' }}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="sln_valid_monthly" style={{ fontWeight: 'normal' }}>
                  <A18n rsx="payments.informations.form.cgv.m3" />
                </label>
                <ErrorBlock error={get(errors, 'checkMonthly')} style={{ paddingLeft: 0 }} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="group">
        <div className="cell">
          <div className="checkboxes">
            <div>
              <input type="checkbox" {...register('checkCgv')} id="sln_validcgv" className={classNames({ warning: !isEmpty(errors.checkCgv) })} />
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={e => {
                if (e.target.tagName.toLowerCase() !== 'a') {
                  setValue('checkCgv', !getValues('checkCgv'));
                }
              }}
            >
              <p>
                <A18n
                  rsx="payments.informations.form.cgv.m4"
                  trustHtml
                  replace={[
                    [
                      '%link1%',
                      <Legals
                        key="legals"
                        legal={PRODUCT}
                        anchorText={i18next.t('payments.informations.form.cgv.m5')}
                        from={ANALYTICS_FROM_FOOTER}
                        op={ANALYTICS_CLICK_OPERATION_TO_CGV}
                        promocode={promocode}
                        id={Object.keys(idphysicproducts || [])}
                        image={image}
                        quantity={quantity}
                        type={type}
                      />,
                    ],
                    ['%link2%', <Legals key="cgv" legal={CGV} anchorText={i18next.t('payments.informations.form.cgv.m6')} from={ANALYTICS_FROM_FOOTER} op={ANALYTICS_CLICK_OPERATION_TO_CGV} />],
                    ['%link3%', <Legals key="privacy" legal={PRIVACY} anchorText={i18next.t('payments.informations.form.cgv.m7')} from={ANALYTICS_FROM_FOOTER} op={ANALYTICS_CLICK_OPERATION_TO_CGV} />],
                  ]}
                />
              </p>
              <ErrorBlock error={get(errors, 'checkCgv')} style={{ paddingLeft: 0 }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Cgv;
