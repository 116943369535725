import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN_OR_REGISTRATION_MODAL_OPEN_CONSTANT } from '../../../../common/LoginOrRegistrationModal/constants';
import { ANALYTICS_SECTION_SUBSCRIPTIONS_TABLE } from '../../../../../constants/rails';
import { updateSettings } from '../../../../../redux/actions/subscriptions/settings';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import { getUserContext } from '../../../../../redux/selectors/userContext';
import { set } from '../../../../../redux/actions/ui/dictionary';
import useHasWarnings from '../../common/useHasWarnings';
import useAnalytics from '../../common/useAnalytics';
import '../../../../common/stylesheets/buttons.scss';
import A18n from '../../../../common/A18n';

const Button = ({ theme, withImages, disabled }) => {
  const dispatch = useDispatch();
  const { quantity, promocode, view } = useSelector(getSettings);
  const { activeSubscription, logged, lastSubscription } = useSelector(getUserContext);
  const hasWarnings = useHasWarnings({ theme, quantity });
  const { addProductTocart } = useAnalytics({ section: ANALYTICS_SECTION_SUBSCRIPTIONS_TABLE });

  const handleSelect = ({ idphysicproduct }) => {
    dispatch(updateSettings({ data: { theme, selectedIdphysicproduct: theme.product.idp, view, image: withImages, quantity, promocode } }));
    addProductTocart({ category: theme.theme, product: theme.product });

    if (logged) {
      if (hasWarnings() || !withImages) {
        dispatch(updateSettings({ data: { displayWarningsModale: true } }));
      } else {
        window.location = `/payments/informations?image=${withImages}&idphysicproducts[${idphysicproduct}]=${quantity}&quantity=${quantity}&type=${view}&promocode=${promocode}`;
      }
    } else {
      dispatch(updateSettings({ data: { selectedIdphysicproduct: idphysicproduct, view, image: withImages, quantity, promocode } }));
      dispatch(set(LOGIN_OR_REGISTRATION_MODAL_OPEN_CONSTANT, true));
    }
  };

  return (
    <button type="button" className="artp-btn artp-btn-purple" disabled={disabled} onClick={() => handleSelect({ idphysicproduct: theme.product.idp })}>
      {view !== 'upgrade' && (
        <>
          {activeSubscription && (
            <>
              {theme.theme === lastSubscription.theme && <A18n rsx="subscriptions.list.table.m6" />}
              {theme.theme !== lastSubscription.theme && <A18n rsx="subscriptions.list.table.m25" />}
            </>
          )}
          {!activeSubscription && <A18n rsx="subscriptions.list.table.m8" />}
        </>
      )}
      {view === 'upgrade' && <A18n rsx="subscriptions.list.table.m7" />}
    </button>
  );
};

export default Button;
