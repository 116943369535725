import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { updateNavigationStep } from '../../../redux/slices/payments';
import { getUserContext } from '../../../redux/selectors/userContext';
import EmailStatus from '../../account/EmailStatus';
import { ok } from '../../../services/payment';
import useQuery from '../hooks/useQueryParams';
import A18n from '../../common/A18n';
import Links from './Links';
import './stylesheet.scss';

const Ok = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const { logged, idcustomer } = useSelector(getUserContext);

  // Set step for the progressbar
  useEffect(() => {
    dispatch(updateNavigationStep(3));
  }, []);

  useEffect(() => {
    const idpayment = query?.get('idpayment');

    if (idpayment) {
      ok({ idpayment }).then(response => {
        setData(response.data);
      });
    }
  }, [query?.get('idpayment')]);

  if (!data.status) return null;

  if (data.status !== 'ok') return <div>KO</div>;

  return (
    <div className="payments-ok">
      <div className="container">
        <div className="block">
          <h1>
            {data.online && <A18n rsx="payments.ok.m1" />}
            {!data.online && <A18n rsx="payments.ok.m10" />}
          </h1>

          <div className="content">
            {data.validatedAt && (
              <>
                <i className="fa fa-check" />
                <p>
                  <A18n rsx="payments.ok.m2" replace={[['%idphysicsale%', data.ref]]} trustHtml />
                </p>
              </>
            )}

            {!data.validatedAt && (
              <>
                <i className="fa fa-hourglass-2" />
                <p>
                  <A18n rsx="payments.ok.m3" replace={[['%idphysicsale%', data.ref]]} trustHtml />
                </p>
              </>
            )}
          </div>
        </div>

        <div className="infos">
          {data.hasSubscriptionStore && !data.isStorePersonalized && (
            <p>
              <A18n rsx="payments.ok.m4" replace={[['%url%', data.urlStore]]} trustHtml />
            </p>
          )}

          {data.emailValidationNeeded && (
            <div className="email-validation">
              {(!(logged && idcustomer) && (
                <p>
                  s1
                  <A18n
                    rsx="payments.ok.m5"
                    replace={[
                      ['%a%', '<a href="/account">'],
                      ['%/a%', '</a>'],
                    ]}
                    trustHtml
                  />
                </p>
              )) ||
                (data.emailValidationRunning && (
                  <>
                    <p>
                      <A18n rsx="payments.ok.m6" trustHtml /> <A18n rsx="payments.ok.m9" trustHtml />
                    </p>
                    <EmailStatus withWarning={false} backTo="home" redirectAfterSending="/account" />
                  </>
                )) || (
                  <>
                    <p>
                      <A18n rsx="payments.ok.m7" trustHtml />
                    </p>
                    <p>
                      <A18n rsx="payments.ok.m8" trustHtml />
                    </p>
                    <EmailStatus withWarning={false} backTo="home" redirectAfterSending="/account" />
                  </>
                )}
            </div>
          )}

          {!data.emailValidationNeeded && (
            <>
              <p>
                <A18n rsx="payments.ok.m8" trustHtml />
              </p>
              <div className="back">
                <Links estimate={data.estimate} hasSubscriptionStore={data.hasSubscriptionStore} lastUrl={data.lastUrl} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Ok;
