/* eslint-disable global-require */
module.exports = [
  ...require('./common/i18n'),
  ...require('./Layout/i18n'),
  ...require('./Ok/i18n'),
  ...require('./Platforms/i18n'),
  ...require('./Informations/i18n'),
  'payments.cartsummary.validatebutton.m1',
  'payments.cartsummary.address.form.tvaintra.m1',
  'payments.cartsummary.validatebutton.m2',
  'payments.cartsummary.validatebutton.m3',
  'payments.cartsummary.validatebutton.m4',
];
