import { useSelector } from 'react-redux';
import { getCart } from '../../../../redux/slices/payments';

export default function useCheckoutGtm() {
  const cart = useSelector(getCart);

  const pushCheckout = platform => {
    const { product, product_quantity: quantity = 1 } = cart.main_products[0];

    const layer = {
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 2,
            option: platform,
          },
          products: [
            {
              name: product.isEstimate ? 'Estimate' : product.reference,
              id: product.id,
              price: product.prices.eur,
              brand: 'Artprice.com',
              category: product.isEstimate ? 'Estimate' : product.theme,
              variant: product.isEstimate ? product.reference : `period-${product.duration}`,
              quantity,
            },
          ],
        },
      },
    };

    if (product.isEstimate) {
      layer.ecommerce.checkout.products[0].list = 'Estimates';
    }

    window.dataLayer.push(layer);
  };

  return { pushCheckout };
}
