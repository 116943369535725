import { useEffect, useState } from 'react';

function getViewport() {
  return {
    viewportWidth: window.innerWidth,
    viewportHeight: window.innerHeight,
  };
}

export function useViewport() {
  const [viewport, setViewport] = useState(getViewport());

  function handleResize() {
    setViewport(getViewport());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return viewport;
}
