import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateNavigationStep } from '../../../redux/slices/payments';
import { getUserContext } from '../../../redux/selectors/userContext';
import AvailablePayments from '../common/AvailablePayments';
import CartSummary from '../common/CartSummary';
import A18n from '../../common/A18n';
import Help from '../common/Help';
import '../common/layout.scss';
import './stylesheet.scss';
import Form from './Form';

const Informations = () => {
  const dispatch = useDispatch();
  const { logged, idcustomer } = useSelector(getUserContext);

  useEffect(() => {
    dispatch(updateNavigationStep(1));

    if (idcustomer && !logged) {
      window.location = '/identity';
    }
  }, []);

  return (
    <div className="payments-informations  payments-grid-layout">
      <div className="container">
        <div className="container-grid">
          <div className="left">
            <h1>
              {!idcustomer && <A18n rsx="payments.informations.m1" />}
              {idcustomer && <A18n rsx="payments.informations.m2" />}
            </h1>
            <Form />
            <div className="help-on-mobile">
              <Help />
              <AvailablePayments />
            </div>
          </div>
          <div className="right">
            <CartSummary withPromocode />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Informations;
