import i18next from 'i18next';
import { Button } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { productLegals } from '../../services/wallet/physicproduct';
import { cgv, marketplaceLegals, privacyPolicy } from '../../services/wordpress';
import { aapiBeacon } from '../../services/analytics/aapi';
import Modal from '../common/ui/Modal';
import A18n from '../common/A18n';
import './stylesheet.scss';

export const CGV = 'cgv';
export const PRODUCT = 'product';
export const PRIVACY = 'privacy_policy';
export const MARKETPLACE_LEGALS = 'marketplace_legals';

const Legals = ({ legal, anchorText, style = {}, className, onClick, onClose, from, op, promocode, id, image, quantity, type, scrollElementId }) => {
  const [open, setOpen] = useState(false);
  const [legalData, setLegalData] = useState();

  const [footerAddon, setFooterAddon] = useState([]);

  useEffect(() => {
    if (open) {
      switch (legal) {
        case PRODUCT:
          productLegals({ promocode, id, image, quantity, type }).then(response => {
            setLegalData(response);
          });
          break;
        case CGV:
          setFooterAddon([
            <Button key="cgv" className="btn btn-default" onClick={() => window.open('/cgv.pdf', 'cgv')}>
              <A18n rsx="legals.m3" />
            </Button>,
          ]);
          cgv().then(response => {
            setLegalData(response);
          });
          break;
        case PRIVACY:
          setFooterAddon([
            <Button key="privacy" className="btn btn-default" onClick={() => window.open('/privacypolicy.pdf', 'cgv')}>
              <A18n rsx="legals.m3" />
            </Button>,
          ]);
          privacyPolicy().then(response => {
            setLegalData(response);
          });
          break;
        case MARKETPLACE_LEGALS:
          setFooterAddon([
            <Button key="marketplace" className="btn btn-default" onClick={() => window.open('/marketplace-legals.pdf', 'marketplace-legals')}>
              <A18n rsx="legals.m3" />
            </Button>,
          ]);
          marketplaceLegals().then(response => {
            setLegalData({ title: i18next.t('legals.m2'), content: response?.content?.unparsed_content });
          });
          break;
        default:
      }
    } else {
      setLegalData(null);
    }
  }, [legal, open]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (scrollElementId && legalData) {
      document.getElementById(scrollElementId).scrollIntoView();
    }
  }, [legalData]);

  const handleClick = () => {
    if (!open) {
      setOpen(true);

      if (from && op) {
        aapiBeacon('click', { from, op });
      }

      if (onClick) onClick();
    }
  };

  return (
    <>
      {/* eslint-disable-next-line react/no-danger */}
      <Modal
        className="legals-modale"
        onClose={handleClose}
        open={open && legalData}
        title={legalData?.title && <span dangerouslySetInnerHTML={{ __html: legalData?.title }} />}
        footer={[
          ...footerAddon,
          <Button key="ok" className="btn btn-primary" onClick={() => setOpen(false)}>
            <A18n rsx="legals.m1" />
          </Button>,
        ]}
      >
        {/* eslint-disable-next-line react/no-danger */}
        {legalData && <div dangerouslySetInnerHTML={{ __html: legalData?.content }} />}
      </Modal>
      <a href="#" onClick={handleClick} className={className} style={style}>
        {anchorText}
      </a>
    </>
  );
};

export default Legals;
