/* eslint-disable global-require */
module.exports = [
  'payments.common.cartsummary.m1',
  'payments.common.cartsummary.m2',
  'payments.common.cartsummary.m3',
  'payments.common.cartsummary.m4',
  'payments.common.cartsummary.m5',
  'payments.common.cartsummary.m6',
  'payments.common.cartsummary.m7',
  'payments.common.cartsummary.m8',
  'payments.common.cartsummary.m9',
  'payments.common.cartsummary.m10',
  'payments.common.cartsummary.m11',
  'payments.common.cartsummary.m12',
  'payments.common.cartsummary.m13',
  'payments.common.cartsummary.m14',
  'payments.common.cartsummary.m15',
  'payments.common.cartsummary.m16',
  ...require('./Promocode/i18n'),
];
